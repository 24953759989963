
































































  import { Component, mixins, Prop } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { Suggestions } from '@one/types'
  import { TranslateResult } from 'vue-i18n'
  import { GTMListType } from '~/plugins/gtm/gtm'
  import AddToCartMixin from '~/mixins/AddToCartMixin'

  const cart = namespace('cart')
  const products = namespace('products')
  const categories = namespace('categories')
  const wishlist = namespace('wishlist')
  const cms = namespace('cms')
  const stocks = namespace('stocks')

  @Component({
    components: {
      OneSearchBar: () => import('~/components/organisms/searchBar/OneSearchBar.vue'),
      OneSuggests: () => import('~/components/organisms/searchBar/OneSuggests.vue'),
      OneSuggestsPriceAndStock: () => import('~/components/organisms/searchBar/OneSuggestsPriceAndStock.vue'),
    },
  })
  export default class SearchComponent extends mixins(AddToCartMixin) {
    @Prop({
      type: Boolean,
      required: false,
      default: true,
    })
    readonly showCategoriesSelect!: boolean

    @categories.Getter sortedMainCategories: any
    @categories.Getter getCategory: any
    @categories.Getter getSelectedCategoryId: any
    @categories.Action selectCategory: any

    @products.State suggest: any
    @products.Getter getSearchedText: any
    @products.Getter getCategorySelected: any
    @products.Action searchChanged: any
    @products.Action updateSearchString: any
    @products.Action fetchSuggestions: any
    @products.Action fetchProductsById: any
    @products.Action fetchPricesForProducts: any
    @products.State((state) => state.productsList.selectedFilters) selectedFilters: any

    @wishlist.Getter getCurrentWishlistId: any
    @wishlist.Action addProductToWishlist: any
    @wishlist.Getter getWishlistProduct: any
    @wishlist.Getter getCurrentWishlist: any

    @cart.Getter getCurrentCartId: any

    @cms.Getter configuration: any
    @cms.State(state => state.configuration.registrationEnabled) isRegistrationEnabled: any

    @stocks.Action fetchStocksForProductsAndWarehouses: any
    @stocks.State warehouses: any

    showSuggest: boolean = false
    maxSuggestPanelWidth: number = 0
    suggestClickConfig: any = {
      handler: this.closeSuggest,
      middleware: (event: any) => !event.target.className.includes('prevent-closing'),
      isActive: this.$mq !== 'xs',
    }

    get selectedCategory(): string {
      return this.getSelectedCategoryId
        ? this.getCategory(this.getSelectedCategoryId).name
        : (this.$t('search.all_categories') as string)
    }

    mounted() {
      window.addEventListener('resize', this.calculateSuggestionPanelWidth)
    }

    destroyed() {
      window.removeEventListener('resize', this.calculateSuggestionPanelWidth)
    }

    calculateSuggestionPanelWidth() {
      if (['xs', 'sm', 'md'].includes(this.$mq)) {
        this.maxSuggestPanelWidth = 0
        return
      }

      const searchBar = document.querySelector('.one-search-component')
      if (window && searchBar) {
        const usableSpace = window.innerWidth
        const bootstrapContainerMaxWidth = 95 // %
        const bootstrapContainerActualWidth = (usableSpace / 100) * bootstrapContainerMaxWidth
        const searchBarOffsetLeft = searchBar.getBoundingClientRect().left
        // using bootstrap container max-width property, we ensure that menu does not overflow screen
        this.maxSuggestPanelWidth = bootstrapContainerActualWidth - searchBarOffsetLeft
      }
    }

    onSearchUpdate(value: string) {
      this.updateSearchString(value)
      if (value.trim() === '') {
        this.closeSuggest()
      } else {
        this.fetchSuggestions(value).then(async ({ products }) => {
          if ((this.configuration.showPricesAndStockQuantityOnSuggest && products.length) && this.$utils.isPurchaseEnabledOrAuthenticated) {
            await this.fetchStocksForProductsAndWarehouses({
              skus: products?.map(el => el.id),
              warehouses: this.warehouses.allIds,
            })
            await this.fetchProductsById(products?.map(p => p.id))
            this.fetchPricesForProducts(products?.map(p => p.id))
          }
          this.$gtm.productSearchSuggestResults({
            query: value,
            skuProducts: products?.map(p => p.id),
            searchIndexes: products?.length,
          })
        })
        this.showSuggest = true
      }
    }

    onSearching(value: string) {
      this.showSuggest = false
      const filters: Array<string> = []
      Object.keys(this.selectedFilters).forEach(el =>
        this.selectedFilters[el]
          .forEach((item: string) => filters.push(`${el}:${item}`)),
      )
      this.$routes.search(value, this.getSelectedCategoryId, { filtersQuery: filters })
    }

    makeSureFocusRemains() {
      // @ts-ignore
      this.$refs.searchBar.focusSearchInput()
    }

    closeSuggest() {
      this.showSuggest = false
    }

    onClearClick() {
      this.updateSearchString('')
      this.closeSuggest()
    }

    onCategoryChanged(category: string | null) {
      this.selectCategory(category).then(() => {
        this.$routes.search(this.getSearchedText, this.getSelectedCategoryId)
      })
    }

    get searchCategories(): Array<any> {
      // noinspection TypeScriptValidateTypes
      return [
        {
          id: null,
          name: this.$t('search.all_categories') as string,
        },
      ].concat(
        this.sortedMainCategories.map((category: string) => ({
          id: category,
          name: this.getCategory(category).name,
        })),
      )
    }

    get infoLabel(): string | TranslateResult {
      return this.isRegistrationEnabled
        ? (this.$t('product_page.login_or_register_to_buy') as string)
        : (this.$t('product_page.login_to_buy') as string)
    }

    onProductAddToCart(product: Suggestions.SuggestedProduct) {
      this.addToCart(product.id, product.measurementUnits.quantityMin, 'suggest')
    }

    onProductAddToWishlist(product: Suggestions.SuggestedProduct) {
      this.addProductToWishlist({
        wishlistId: this.getCurrentWishlistId,
        newProduct: {
          productId: product.id,
          amountOfProducts: product.measurementUnits.quantityMin,
        },
      }).then(() => {
        this.$notify({
          group: 'alert',
          type: 'success',
          text: this.$t('product_page.alert_added_to_wishlist') as string,
        })
        const addedProduct = this.getWishlistProduct(this.getCurrentWishlistId)(product.id)
        let discount = addedProduct.prices?.catalogPriceNet - addedProduct.prices?.unitNet
        discount = discount > 0 ? discount : 0
        this.$gtm.addToWishlist(
          {
            item_id: addedProduct.id,
            item_name: addedProduct.name,
            item_list_name: this.getCurrentWishlist.name,
            item_brand: addedProduct.brand?.name || '',
            category: this.$routes.getCategoryPath(addedProduct.canonicalCategory) || '',
            price: addedProduct.prices?.unitNet || 0,
            discount: discount,
            quantity: product.measurementUnits.quantityMin || 0,
          },
          product.measurementUnits.quantityMin * addedProduct.prices?.unitNet || 0,
        )
      })
    }

    onProductClick(product) {
      this.$gtm.selectItem({
        item_id: product.id,
        item_name: product.name,
        item_list_name: GTMListType.SUGGEST,
        item_brand: (product.brand && product.brand?.name) || product.manufacturerName,
        category: this.$routes.getCategoryPath(product?.canonicalCategory) || '',
      })
      this.$router.push(this.$routes.getProductPath(product), () => {
        this.closeSuggest()
      })
    }

    onCategoryClick(category: Suggestions.SuggestedCategory | string) {
      const categoryId = typeof category === 'string' ? category : category.id
      this.updateSearchString('')
      const categoryPath: string | null = this.$routes.getCategoryPath(categoryId)
      if (categoryPath) {
        this.$router.push(categoryPath, () => {
          this.closeSuggest()
        })
      }
    }

    onManufacturerClick(manufacturer: Suggestions.CharacteristicValue) {
      this.$router.push(this.$routes.getManufacturerSearchUrl(manufacturer.valueCode), () => {
        this.closeSuggest()
      })
    }
  }
