import { Component, namespace, Vue } from 'nuxt-property-decorator'
import { BindingHelpers } from 'vuex-class/lib/bindings'
import { Plugins, Products, Stocks } from '@one/types'
import {
  Cart,
  Shared,
} from '@one/types/dist/orderpath/app'
import { AddToCartContext } from '~/plugins/sdk/AddToCartContext'
import ProductInCart = Shared.ProductInCart
import Type = Plugins.Type

const cart: BindingHelpers = namespace('cart')
const products: BindingHelpers = namespace('products')
const stocks: BindingHelpers = namespace('stocks')
const plugins: BindingHelpers = namespace('plugins')
const DEFAULT_NOTIF_DURATION: number = 5000

export const ERRORS = {
  NO_CART_AVAILABLE: 'NO_CART_AVAILABLE',
  PRODUCT_NOT_EXISTS: 'PRODUCT_NOT_EXISTS',
  INVALID_AMOUNT: 'INVALID_AMOUNT',
  NO_PRICE_AVAILABLE: 'NO_PRICE_AVAILABLE',
  PRODUCT_BLOCKED: 'PRODUCT_BLOCKED',
  INVALID_ID: 'INVALID_ID',
  BLOCK_PRODUCT_ABOVE_STOCK: 'BLOCK_PRODUCT_ABOVE_STOCK',
}

type ValidationSuccess = boolean
type ValidationError = [boolean, string]
type ValidationResult =
  | ValidationSuccess
  | ValidationError
  | Promise<ValidationSuccess | ValidationError>

@Component
export default class AddToCartMixin extends Vue {
  @cart.Action public addProductToCart: any
  @cart.Action public addSelectedProductsToCart: any
  @cart.Getter public getCurrentCartId: any
  @cart.Getter public getCartProduct: any
  @cart.Getter public getCartProducts!: (cartId: string) => Map<string, ProductInCart>
  @cart.Getter public getCurrentCart: any
  @cart.Getter public getTotalProductCountInCart: any

  @products.Getter
  public readonly getProduct!: Function

  @products.Getter public readonly productPrice!: Function
  @products.Action public fetchProductPrice!: Function
  @products.Action public fetchProductWithPriceAndStock!: Function
  @plugins.Action public getPluginIdToLaunch!: Function
  @stocks.Getter public readonly sumProductQuantityInWarehouses!: Function
  @stocks.Getter public readonly getDefaultWarehouseId!: string
  @stocks.Getter public readonly getWarehousesList!: Array<Stocks.Warehouse>

  addToCartValidators = [this.isAmountValid, this.isPriceAvailable]

  defaultErrorOptions = (error: string) => ({
    group: 'alert',
    type: 'error',
    text: error,
  })

  validationErrorsOptions: any = {
    [ERRORS.BLOCK_PRODUCT_ABOVE_STOCK](product: Products.Product) {
      const quantityInWarehouses = this.sumProductQuantityInWarehouses(product.id)
      return {
        ignoreDuplicates: true,
        group: 'addToCartAlert',
        type: 'error',
        duration: DEFAULT_NOTIF_DURATION,
        title: `${this.$t('product_block.title_one')} ${product.name} ${this.$t(
          'product_block.title_two',
        )}`,
        text: `${this.$t('product_block.text')} ${quantityInWarehouses}`,
      }
    },
    [ERRORS.PRODUCT_BLOCKED](product: Products.Product) {
      // TODO: Poprawić teksty
      return {
        ignoreDuplicates: true,
        group: 'addToCartAlert',
        type: 'error',
        duration: DEFAULT_NOTIF_DURATION,
        title: `${this.$t('product_block.product')} ${product.name} ${this.$t(
          'product_block.is_blocked',
        )}`,
        text: `${this.$t('product_block.product_blocked_one')} ${product.id} ${this.$t(
          'product_block.product_blocked_two',
        )} ${product.labels}`,
      }
    },
    [ERRORS.NO_PRICE_AVAILABLE](product: Products.Product) {
      return {
        ignoreDuplicates: true,
        group: 'addToCartAlert',
        type: 'error',
        duration: DEFAULT_NOTIF_DURATION,
        title: `${this.$t('product_block.cannot_add')} ${product.name}`,
        text: this.$t('product_block.no_price_available') as string,
      }
    },
    [ERRORS.INVALID_AMOUNT](product: Products.Product) {
      // TODO: Poprawić teksty
      return {
        ignoreDuplicates: true,
        group: 'addToCartAlert',
        type: 'error',
        duration: DEFAULT_NOTIF_DURATION,
        title: `${this.$t('product_block.cannot_add')} ${product.name}`,
        text: this.$t('product_block.invalid_amount') as string,
      }
    },
  }

  private noAvailableCartError = () =>
    this.$notify(this.defaultErrorOptions('No cart is available'))

  private invalidSKUError = () => this.$notify(this.defaultErrorOptions('Invalid product SKU'))
  private productDoesNotExistError = () =>
    this.$notify(this.defaultErrorOptions('Product does not exists'))

  private customResponseError = (text: string) => this.$notify(this.defaultErrorOptions(text))

  private showError(error: string, product: Products.Product, amount: number | null) {
    if (error in ERRORS) {
      this.$notify(this.getErrorOptions(error).call(this, product, amount))
    }
  }

  private showErrors(errors: Array<string>, product: Products.Product, amount: number | null) {
    errors.forEach((error: string) => {
      this.$notify(this.getErrorOptions(error).call(this, product, amount))
    })
  }

  private handleResponseError(e: any, product: Products.Product, amount: number | null) {
    if (e.response.status === 400) {
      if (e.response.data.type === ERRORS.BLOCK_PRODUCT_ABOVE_STOCK) {
        this.showError(ERRORS.BLOCK_PRODUCT_ABOVE_STOCK, product, amount)
      } else {
        this.showError(ERRORS.INVALID_AMOUNT, product, amount)
      }
    } else if (e.response.status === 404) {
      this.customResponseError(e.response.data.message)
    }
  }

  private getErrorOptions(error: string): Function {
    if (this.validationErrorsOptions[error]) {
      return this.validationErrorsOptions[error]
    }
    return () => this.defaultErrorOptions(error)
  }

  private runValidators = async (
    product: Products.Product,
    amount: number,
  ): Promise<boolean | Array<string>> => {
    const errors = (
      await Promise.all(
        this.addToCartValidators.map((validator: any) => validator(product, amount)),
      )
    ).filter((result: ValidationResult) => Array.isArray(result))
    if (errors.length) {
      return errors.map((error: ValidationError) => error[1])
    }
    return true
  }

  private async isPriceAvailable(product: Products.Product): Promise<ValidationResult> {
    let price: Products.ProductPrice = this.productPrice(product.id)
    if (!price) {
      price = await this.$store.dispatch('products/fetchProductPrice', {
        productId: product.id,
        quantity: 1,
      })
    }
    if (!price) {
      return [false, ERRORS.NO_PRICE_AVAILABLE]
    }
    return true
  }

  private isAmountValid(product: Products.Product, amount: number): ValidationResult {
    const units: Shared.ProductMeasurementUnits = product.measurementUnits
    const checkIfIsMemberOfArithmeticProgression = (amountToCheck: number): boolean => {
      const startNumber: number = units.quantityMin || 1
      const progressionNumber: number | null = units.quantityInterval || null
      if (!progressionNumber || progressionNumber === 0) {
        return startNumber === amountToCheck
      }
      // eslint-disable-next-line max-len
      return (
        (amountToCheck - startNumber) % progressionNumber === 0 &&
        (amountToCheck - startNumber) / progressionNumber >= 0
      )
    }
    const checkIfAmountIsHigherOrEqualThanMinQuantity = (amountToCheck: number): boolean => {
      if (!units.quantityMin || !Number.isInteger(units.quantityMin)) {
        return true
      }
      return amountToCheck >= units.quantityMin
    }
    const basicAmountValidator = (modifiedAmount: number): ValidationResult => {
      const isPositiveNumber: boolean = modifiedAmount > 0
      const isInProperInterval: boolean = checkIfIsMemberOfArithmeticProgression(modifiedAmount)
      // eslint-disable-next-line max-len
      const isHigherThanMinQuantity: boolean =
        checkIfAmountIsHigherOrEqualThanMinQuantity(modifiedAmount)
      return (
        (isPositiveNumber && isInProperInterval && isHigherThanMinQuantity) || [
          false,
          ERRORS.INVALID_AMOUNT,
        ]
      )
    }
    // eslint-disable-next-line max-len
    const hasMinQtyAndInterval = (): ValidationResult =>
      !!(units.quantityMin && units.quantityInterval)

    // eslint-disable-next-line max-len
    const productInCart: ProductInCart | null = this.getCartProduct(this.getCurrentCartId)(
      product.id,
    )
    if (!hasMinQtyAndInterval()) {
      return true
    }
    if (productInCart !== null) {
      // eslint-disable-next-line max-len
      const cartQuantity: number = this.getTotalProductCountInCart(
        product.id,
        this.getCurrentCartId,
      )
      const modifiedProductQuantity: number = cartQuantity + amount
      return basicAmountValidator(modifiedProductQuantity)
    }
    return basicAmountValidator(amount)
  }

  public async getOrFetchProduct(productId: string): Promise<Products.Product | null> {
    try {
      return this.getProduct(productId) || (await this.fetchProductWithPriceAndStock(productId))
    } catch (e) {
      return Promise.resolve(null)
    }
  }

  async defaultAddToCartFlow(product: Products.Product, amount: number, where: string, cartId: string) {
    try {
      const errors = await this.runValidators(product, amount)
      if (Array.isArray(errors)) {
        this.showErrors(errors, product, amount)
        return null
      }
      await this.addProductToCart({
        cartId: cartId,
        newProduct: {
          productId: product.id,
          amountOfProducts: amount,
        },
        warehouseId: this.getDefaultWarehouseId,
      })
      this.pushGtmEvent(product, where, amount)
      this.$notify({
        group: 'alert',
        type: 'success',
        text: this.$t('product_page.alert_added_to_cart') as string,
      })
      return product
    } catch (e: any) {
      if (e.response?.errorCode === 'orderpath-backoffice-cart-CartNotFoundError') {
        const newCart: string = await this.$orderpath.cart.createCartAndSelect()
        if (newCart) {
          return this.addToCart(product.id, amount, where, newCart)
        }
      }
      this.$logger.warn(e)
      if (e.response) {
        this.handleResponseError(e, product, amount)
      }
      return null
    }
  }

  async addToCart(
    productId: string,
    amount: number,
    where: string,
    cartId?: string,
  ): Promise<Products.Product | null> {
    const currentCartId: string = cartId || this.getCurrentCartId
    if (!currentCartId) {
      this.noAvailableCartError()
      return null
    }
    if (productId.trim() === '') {
      this.invalidSKUError()
      return null
    }
    const product: Products.Product | null = await this.getOrFetchProduct(productId)
    if (!product) {
      this.productDoesNotExistError()
      return null
    }
    if (Object.keys(product.pluginsData).length < 1) {
      return this.defaultAddToCartFlow(product, amount, where, currentCartId)
    } else {
      const pluginId = await this.getPluginIdToLaunch({
        productPlugins: product.pluginsData,
        type: Type.IFRAME_SHOP_ADD_TO_CART,
      })

      if (!pluginId) {
        return this.defaultAddToCartFlow(product, amount, where, currentCartId)
      }
      try {
        const cartProducts: Array<ProductInCart> = Array.from(this.getCartProducts(currentCartId).values())
        const context = new AddToCartContext(pluginId, this.$store, {
          meta: product.pluginsData[pluginId],
          pluginProductLines: [],
          productLines: cartProducts,
          warehouseId: this.getDefaultWarehouseId,
          warehouses: this.getWarehousesList,
          cartId: currentCartId,
          productId: product.id,
          productAmount: amount,
        })
        context.onClose(() => {
          this.$api.orderpath.app.invalidate(['cart'])
          this.$orderpath.cart.fetchCart(this.getCurrentCartId)
          return false
        })
        context.open()
      } catch (e) {
        console.log(e)
        return this.defaultAddToCartFlow(product, amount, where, currentCartId)
      }
    }
    return null
  }

  private pushGtmEvent(product: Products.Product, where: string, amount: number) {
    const price = this.productPrice(product.id)
    const discount = (price?.catalogPriceNet - price?.priceNet) || 0
    this.$gtm.productAdd(
      {
        item_id: product.id,
        item_name: product.name,
        item_list_name: where,
        item_brand: product.brand?.name || '',
        category: this.$routes.getCategoryPath(product.canonicalCategory) || '',
        price: price?.priceNet || 0,
        discount: discount,
        quantity: amount,
      },
      price?.priceNet * amount ?? 0,
    )
  }

  async addSelectedToCart(
    newProducts: Array<Cart.Requests.AddProductToCart>,
    where: string,
    cartId?: string,
  ): Promise<any> {
    const currentCartId: string = cartId || this.getCurrentCartId
    if (!currentCartId) {
      this.noAvailableCartError()
      return null
    }
    newProducts.map(async (elem: Cart.Requests.AddProductToCart) => {
      if (elem.productId.trim() === '') {
        this.invalidSKUError()
        return null
      }
      const product: Products.Product | null = await this.getOrFetchProduct(elem.productId)
      if (!product) {
        /*
            FIXME: W ramach UNITYONE-7119 ukrywam ten popup do momentu,
                   aż catalog nie udostępni nowego endpointa
         */
        // this.productDoesNotExistError()
        return null
      }
      const errors = await this.runValidators(product, elem.amountOfProducts)
      if (Array.isArray(errors)) {
        this.showErrors(errors, product, elem.amountOfProducts)
        return null
      }
    })
    try {
      await this.addSelectedProductsToCart({
        cartId: currentCartId,
        newProducts: newProducts,
        warehouseId: this.getDefaultWarehouseId,
      })
      newProducts.map(async (newProduct: Cart.Requests.AddProductToCart) => {
        const price: Products.ProductPrice = this.productPrice(newProduct.productId)
        const product: Products.Product | null = await this.getOrFetchProduct(newProduct.productId)
        const discount = price.catalogPriceNet || 0 - price.priceNet || 0

        if (product) {
          const { name, id, manufacturer, canonicalCategory, brand } = product
          this.$gtm.productAdd(
            {
              item_name: name,
              item_id: id,
              item_list_name: where,
              item_brand: (brand && brand.name) || manufacturer.name,
              category: this.$routes.getCategoryPath(canonicalCategory) || '',
              quantity: newProduct.amountOfProducts,
              price: price.priceNet,
              discount: discount,
            },
            price.priceNet * newProduct.amountOfProducts,
          )
        }
      })
      this.$notify({
        group: 'alert',
        type: 'success',
        text: this.$t('product_page.alert_added_to_cart') as string,
      })
      return Promise.resolve(true)
    } catch (e: any) {
      if (e.response) {
        e.response.data.map(async (newProduct: Cart.Requests.AddProductToCart) => {
          const product: Products.Product | null = await this.getOrFetchProduct(
            newProduct.productId,
          )
          if (!product) {
            this.productDoesNotExistError()
            return null
          }
          this.handleResponseError(e, product, null)
        })
      }
      return Promise.reject(e)
    }
  }
}
