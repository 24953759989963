import { Plugins } from '@one/types'
import { Shop } from '@one-commerce/sdk-shared'
import { Store } from 'vuex'
import Type = Plugins.Type
import { ExtendedComponent } from '~/store/plugins'

export interface CloseOptions {
  addProductToCart: boolean
  refreshCart: boolean
}

export class AddToCartContext {
  type: Type = Type.IFRAME_SHOP_ADD_TO_CART
  hooks = {
    onClosed: (_cb: CloseOptions): Promise<boolean> | boolean => {
      return false
    },
  }

  component: ExtendedComponent;

  constructor(
    private readonly pluginId: string,
    private readonly store: Store<any>,
    private readonly context: Omit<
      Shop.Context.AddToCart,
      'closePlugin' | 'closePluginAndAddToCart' | 'closePluginAndRefreshCart'
    >,
  ) {
    this.component = this.store.getters['plugins/getComponentByPluginIdAndType']({ pluginId: this.pluginId, type: this.type })
    if (!this.component) {
      throw new Error(`Plugin with id ${this.pluginId} does not have ${Type.IFRAME_SHOP_ADD_TO_CART} component`)
    }
  }

  private close = async (closePayload: CloseOptions) => {
    const isCanceled = await this.hooks.onClosed(closePayload)
    if (!isCanceled) {
      await this.store.commit('plugins/hideModalPlugin')
    }
  }

  private closePlugin = () => { return this.close({ refreshCart: false, addProductToCart: false }) }
  private closePluginAndAddToCart = () => { return this.close({ refreshCart: false, addProductToCart: true }) }
  private closePluginAndRefreshCart = () => { return this.close({ refreshCart: true, addProductToCart: false }) }

  public onClose = (cb: (params: CloseOptions) => Promise<boolean> | boolean) => {
    this.hooks.onClosed = cb
  }

  public open = async () => {
    const context = {
      ...this.context,
      closePlugin: this.closePlugin,
      closePluginAndAddToCart: this.closePluginAndAddToCart,
      closePluginAndRefreshCart: this.closePluginAndRefreshCart,
    }
    await this.store.commit('plugins/addModalPluginContextData', { componentId: this.component.id, context })
    return this.store.dispatch('plugins/showModalPlugin', this.component.id)
  }
}
